import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { graphql } from 'gatsby'

const cursoTemplate = ({data}) => {
    console.log(data.allCursosYaml)
    const curso = data.allCursosYaml.edges[0].node.curso 
    const cur = data.allCursosYaml.edges[0].node.content
    return (
    <Layout color="blue" breadcrumbs={[cur.titulo]} item="2">
        <section class="banner_area">
        <div class="banner_inner d-flex align-items-center">
            <div class="overlay"></div>
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                <div class="banner_content text-center">
                    <h2>{cur.titulo}</h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section class="course_details_area section_gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 course_details_left">
                        <div class="main_image">
                            <img class="img-fluid-adap" src={"/img/courses/"+curso+".jpg"} alt="" />
                        </div>
                        <div class="content_wrapper">
                            <h4 class="title">Objetivo</h4>
                            <div class="content">{cur.objetivo}</div>
                            <h4 class="title">A quem se destina</h4>
                            <div class="content">{cur.destino}</div>
                            <h4 class="title">Elegibilidade</h4>
                            <div class="content">{cur.elegibilidade}</div>
                            <h4 class="title">Benefícios do Curso</h4>
                            <div class="content" style={{whiteSpace:"pre-line"}}>{cur.beneficios}</div>

                            <h4 class="title">Grade do curso</h4>
                            <div class="content">
                                <ul class="course_list">
                                    {cur.grade.map((dt, index) => {
                                        return <li>
                                                <p class="justify-content-between d-flex">
                                                    <p>{dt.nome}</p>
                                                    <span class="primary-btn text-uppercase">{dt.duracao}</span>
                                                </p>
                                                </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 right-contents">
                        <ul>
                            <li>
                                <a class="justify-content-between d-flex">
                                    <p>Carga Horária</p>
                                    <span class="or">{cur.carga}h</span>
                                </a>
                            </li>
                            <li>
                                <a class="justify-content-between d-flex">
                                    <p>Coordenação</p>
                                    <span class="or">{cur.coordenador}</span>
                                </a>

                            </li>
                        </ul>
                        <Link to="/inscrever" className="primary-btn2 text-uppercase enroll rounded-0" state={{ curso: cur.titulo}}>Inscrever-se hoje</Link>

                        <h4 class="title">Sistema de Ensino</h4>
                        <div class="content">
                            <div class="review-top row pt-40">
                                <div class="col-lg-12">
                                    <p>
                                    {cur.sistema}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  );
}

export const query = graphql`
    query CursoQuery($curso: String) {
        allCursosYaml(filter: {curso: {eq: $curso}}) {
            edges {
                node {
                    curso
                    content {
                        titulo
                        objetivo
                        beneficios
                        grade{
                            nome
                            duracao
                        }
                        carga
                        coordenador
                        elegibilidade	
                        destino
                        sistema
                    }
                }
            }
        }
    }
`

export default cursoTemplate